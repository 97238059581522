/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7a56498359345141-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0af42c81f13c95f7-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/57fd2099f2759e34-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/207391440502d11a-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1692cda41047b6bb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9404d45777235b9e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/098064a1ece52d38-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c9a7458d7f4217aa-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5fdec3abc7c495a8-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1e7a0e2b5bf5a671-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c1665efa86008021-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/edba196c1084e400-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0ac35c571cf56839-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/634ff3e68456c607-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3f3dd1814c28e011-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/35825d466b6d011b-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e702ea0d6abff09f-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a683b048a20962e0-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3ebbc3de588b2e2c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0ac4771070adefd9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2fa4c0ce5c452366-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/382b6f43e60580be-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/42aa6e6ea3b85651-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7bafef50a4560c1d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bc270b3a577192a7-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b32bc20a1e277f31-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/357398c3a461852b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4d1af68a2715a16a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d1efd2a880aff16c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5617f5a793538eec-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a730c2098cf2d406-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/10020a8c5cd83a9c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26e111bc0bbb9793-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1d0c179378730186-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7806ef5df85cda4a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6821bb67d8dbee23-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fe049333cc50d32b-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a8f1d802d3a50c22-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d5e97bb9bb0471a9-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bf219fb9b8e9c4d9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2955b29b2e789dbf-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Sans_19ff8e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/83c07cea5b6249d7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Fira_Sans_Fallback_19ff8e';src: local("Arial");ascent-override: 91.01%;descent-override: 25.79%;line-gap-override: 0.00%;size-adjust: 102.74%
}.__className_19ff8e {font-family: '__Fira_Sans_19ff8e', '__Fira_Sans_Fallback_19ff8e'
}.__variable_19ff8e {--fira-sans-google: '__Fira_Sans_19ff8e', '__Fira_Sans_Fallback_19ff8e'
}

